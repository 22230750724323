import patrickEsteruelasImage from './images/patrick-esteruelas.jpg'
import marcFranklinImage from './images/marc-franklin.jpg'
import bobMessengerImage from './images/bob-messenger.jpg'
import chrisKeableImage from './images/chris-keable.jpg'
import michielImage from './images/michiel-jansen.jpg'
import giorgioImage from './images/giorgio-lombardo.jpg'
import jensImage from './images/jens-nystedt.jpg'
import martinImage from './images/martin-tyler.jpg'
import johanImage from './images/johan.jpg'
import donaldImage from './images/donald.jpg'
import sliderImage1 from './images/team/team-slider-1.jpg'
import sliderImage2 from './images/team/team-slider-2.jpg'
import sliderImage3 from './images/team/team-slider-3.jpg'
import sliderImage4 from './images/team/team-slider-4.jpg'
import sliderImage5 from './images/team/team-slider-5.jpg'
import sliderImage6 from './images/team/team-slider-6.jpg'
import sliderImage7 from './images/team/team-slider-7.jpg'

const imageCredit = 'Sir Don McCullin'
const secondImageCredit = 'Panel Eight Photography'
const thirdImageCredit = 'London Corporate Headshots'

export const heroData = {
  title: 'Team',
  header: "Members of Emso's senior investment team average over 20 years of experience investing in emerging markets",
  content: [
    "Emso has an integrated portfolio management and research team, with diverse and extensive experience in the relevant disciplines for analyzing and investing in emerging markets. Emso's team frequently travels to investigate attractive investment opportunities. Spanish, Urdu, Portuguese, Mandarin, and Russian are just a sampling of the languages spoken fluently by the team.",
    "Emso's support teams are built and managed by a senior team averaging over 30 years of experience in legal, compliance, operations, and investor relations.",
  ],
}

export const sliderImages = [
  {
    image: sliderImage1,
    imageCredit,
  },
  {
    image: sliderImage2,
    imageCredit: secondImageCredit,
  },
  {
    image: sliderImage3,
    imageCredit,
  },
  {
    image: sliderImage4,
    imageCredit: secondImageCredit,
  },
  {
    image: sliderImage5,
    imageCredit,
  },
  {
    image: sliderImage6,
    imageCredit: secondImageCredit,
  },
  {
    image: sliderImage7,
    imageCredit: thirdImageCredit,
  },
]

const mark = {
  image: marcFranklinImage,
  imageCredit,
  name: 'Mark Franklin',
  position: 'CIO & Founder',
  bio: `Prior to founding Emso in 2000, Mark established the emerging markets customer and investment businesses at Salomon Brothers in London and New York. Mark has a BA from Brown University in Economics. He is a member of the UK Development Board of Tusk, a pan-African charitable trust.`,
}

const patrick = {
  image: patrickEsteruelasImage,
  imageCredit,
  name: 'Patrick Esteruelas',
  position: 'Investment Team',
  bio: `Prior to joining Emso in 2011, Patrick worked at the Inter-American Development Bank, Eurasia Group, and Moody’s. Patrick has an MA in International Relations and Economics from Johns Hopkins School of Advanced International Studies and a BA in European Studies from the London School of Economics.`,
}

const bob = {
  image: bobMessengerImage,
  imageCredit: secondImageCredit,
  name: 'Bob Messenger',
  position: 'Chief Technology Officer',
  bio: `Bob began his career at Salomon Brothers in 1997. Prior to joining Emso in 2007, he used his background as a software engineer to author trading systems in the finance sector. Bob has a BSc from Nottingham University.`,
}

const chris = {
  image: chrisKeableImage,
  imageCredit: secondImageCredit,
  name: 'Chris Keable',
  position: 'Head of Finance and Middle Office Operations',
  bio: `Prior to joining Emso in 2011, Chris worked at GlobeOp Financial Services (now SS&C Financial Services) and Citi Alternative Investments. Chris has a BSc from the University of Portsmouth.`,
}

const johan = {
  image: johanImage,
  imageCredit: secondImageCredit,
  name: 'Johan Larsson',
  position: 'Investor Relations',
  bio: `Prior to joining Emso in 2017, Johan worked at Och-Ziff Capital Management, IPM, SEB Merchant Banking, and Olympia Capital Management. Johan has a MSc from Jonkoping International Business School. `,
}

const michiel = {
  image: michielImage,
  imageCredit: secondImageCredit,
  name: 'Michiel Jansen',
  position: 'Deputy Chief Operating Officer',
  bio: `Prior to joining the Emso team in 2009, Michiel held positions at Rabobank, UBS, TD Securities, and Citigroup. Michiel has a MA from Erasmus University.`,
}

const giorgio = {
  image: giorgioImage,
  imageCredit: secondImageCredit,
  name: 'Giorgio Lombardo',
  position: 'Chief Risk Officer',
  bio: `Prior to joining Emso in 2007, Giorgio worked at Eurostat, Salomon Brothers, CLMP Software Research, and Banca Fideuram. Giorgio has a MSc from the University of Genoa and completed the Investment Management Programme at the London Business School.`,
}

const donald = {
  image: donaldImage,
  imageCredit: secondImageCredit,
  name: 'Donald Lucardi',
  position: 'Global Head of Investor Relations',
  bio: `Donald began working with Emso in 2007 and prior to that held positions at Citi Capital Advisors, LBBW, Bank Austria, UBS/Swiss Bank, Credit Suisse First Boston, and Coopers & Lybrand. He has a MSc and BS from Syracuse University in Accounting and is a CPA. `,
}

const jens = {
  image: jensImage,
  imageCredit: secondImageCredit,
  name: 'Jens Nystedt',
  position: 'Investment Team',
  bio: `Prior to joining Emso in 2017, Jens held various positions at the IMF, Deutsche Bank, GLG, Moore Capital, and Morgan Stanley Investment Management. Jens has a PhD and a MSc from the Stockholm School of Economics.`,
}

const martin = {
  image: martinImage,
  imageCredit: secondImageCredit,
  name: 'Martin Tyler',
  position: 'Head of Engineering',
  bio: `Prior to joining Emso in 2016, Martin spent 18 years as Chief Software Architect at Caplin Systems. Martin has a BSc from Nottingham University.`,
}

const employees = [patrick, bob, chris, johan, michiel, giorgio, donald, jens, martin]

// sort by surname
const sortedEmployees = employees.sort((a, b) => {
  const surnameA = a.name.split(' ')[1]
  const surnameB = b.name.split(' ')[1]
  if (surnameA < surnameB) {
    return -1
  }
  if (surnameA > surnameB) {
    return 1
  }
  return 0
})

export default [].concat(sortedEmployees)
