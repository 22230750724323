import * as React from 'react'
import { get } from 'lodash'
import { sliderImages } from '../../data/team'
import { TeamMember } from '../TeamMember/TeamMember'
import * as style from './team.module.scss'
import SlickSlider from '../SlickSlider/SlickSlider'
import { Waypoint } from 'react-waypoint'
import PeopleQuery from '../../apollo/query/people/PeopleList'
import Loader from '../Login/Loader/Loader'
import auth from 'src/helpers/auth'
import { getUnpublishedStatusIfHasChanges, isArchived } from 'src/utils/helpers/insight'
import { sortPeople } from 'src/utils/helpers/team'
import { getPublicTeamParams } from '../../apollo/helpers/people'

class Team extends React.Component {
  state = { teamHidden: true, teamMemberSelected: -1 }

  getTeamMembers(selectedId, people) {
    const topSliderLimit = Math.ceil(sliderImages.length / 2)
    const sortedPeople = sortPeople(people)

    const renderedTeamMembers = sortedPeople.map((member, index) => (
      <TeamMember
        key={member.uuid}
        id={index}
        open={selectedId === index}
        {...member}
        onOpen={this.onTeamMemberOpened.bind(this)}
        onClose={this.onTeamMemberClosed.bind(this)}
      />
    ))
    // desktop: first slider inserted after first team image
    renderedTeamMembers.splice(
      1,
      0,
      <li key={1} className={style.firstSlider}>
        <SlickSlider images={sliderImages.slice(0, topSliderLimit)} />
      </li>,
    )
    // desktop: second slider inserted halfway through team images
    renderedTeamMembers.splice(
      Math.floor(renderedTeamMembers.length / 2),
      0,
      <li key={2} className={style.secondSlider}>
        <SlickSlider images={sliderImages.slice(topSliderLimit)} />
      </li>,
    )
    // mobile: first slider inserted 1/3 of the way through the team images
    renderedTeamMembers.splice(
      Math.floor(renderedTeamMembers.length / 3),
      0,
      <li key={4} className={style.mobileSlider}>
        <SlickSlider images={sliderImages.slice(0, topSliderLimit)} />
      </li>,
    )
    // mobile: second slider inserted 2/3 of the way through the team images
    renderedTeamMembers.splice(
      Math.floor(renderedTeamMembers.length / 3 * 2),
      0,
      <li key={5} className={style.mobileSlider}>
        <SlickSlider images={sliderImages.slice(topSliderLimit)} />
      </li>,
    )
    return renderedTeamMembers
  }

  onTeamEntered() {
    this.setState({ teamHidden: false })
  }

  onTeamMemberOpened(id) {
    this.setState({ teamMemberSelected: id })
  }

  onTeamMemberClosed() {
    this.setState({ teamMemberSelected: -1 })
  }

  render() {
    const isAdmin = auth().isAdmin()
    return (
      <Waypoint onEnter={this.onTeamEntered.bind(this)}>
        <section data-hidden={this.state.teamHidden} className={style.team}>
          <PeopleQuery {...getPublicTeamParams(isAdmin)}>
            {({ data, loading }) => {
              const people = get(data, 'people.results', [])
              const items = isAdmin ? getUnpublishedStatusIfHasChanges(people).filter(item => !isArchived(item)) : people

              if (loading) return <Loader />
              return <ul className={style.teamMembers}>{this.getTeamMembers(this.state.teamMemberSelected, items)}</ul>
            }}
          </PeopleQuery>
        </section>
      </Waypoint>
    )
  }
}

export default Team
